import Cardteam from "./Cardteam";
import { motion } from "framer-motion";
import "../../App.css";
import useMediaQuery from "../../hooks/useMediaQuery";
export default function SpecificEvent(props) {
  const isDesktop = useMediaQuery("(min-width:800px)");
  const onlyNamesContent = (
    <div className="flex items-center justify-around border-2 p-4 m-8 rounded-lg w-3/4">
      {props.onlyNames?.map((item, index) => {
        return (
          <div className="text-white mx-4 select-none text-center">{item}</div>
        );
      })}
    </div>
  );

  return (
    <div
      className={`bgimg flex h-full flex-col pt-10 justify-start ${
        isDesktop ? "w-[calc(75vw-5px)]" : ""
      } items-center music object-contain`}
    >
      {/* //text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 */}
      <div
        className={`text-6xl font-Kojiro select-none font-normal ${props.fontColor}`}
      >
        {props.name}
      </div>

      {props.data ? (
        <div className="properties overflow-y-scroll">
          {props.data.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, translateX: -50 }}
              animate={{ opacity: 1, translateX: 0 }}
              transition={{ duration: 0.8 + index / 2 }}
            >
              <Cardteam artistFlag={props.artistFlag} data={item} key={index} />
            </motion.div>
          ))}
        </div>
      ) : null}
      {props.onlyNames ? onlyNamesContent : null}
    </div>
  );
}
