import React, { useContext } from "react";
import SideBarteam from "./SideBarteam";
import { GiHamburgerMenu } from "react-icons/gi";
import "./LeftSectionteam.css";
import useMediaQuery from "../../hooks/useMediaQuery";
import HamContext from "../../store/ham-context";

export default function LeftSection(props) {
  const isMobile = useMediaQuery("(max-width: 800px)");
  const isDesktop = useMediaQuery("(min-width: 800px)");
  const hamctx = useContext(HamContext);

  return (
    <>
      {isMobile && (
        <GiHamburgerMenu
          color="white"
          size={35}
          onClick={() => {
            hamctx.toggle();
          }}
          className="z-40 fixed p-2 HamIcon"
        />
      )}
      {hamctx.isOpen && isMobile && <SideBarteam className={`test`} />}
      {isDesktop && <SideBarteam />}
    </>
  );
}
