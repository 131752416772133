export const lcData = [
  {
    title: "Afreen",
    imageUrl: "afreen", //repeated image
    description:
      "“Poetry is when an emotion has found its thought and the thought has found words.” Vivacity'22 brings to you an evening filled with poetry Afreen in collaboration with The Literary Committee. Poetry is a magnificent form of expression that stimulates a focused, creative awareness of reality. An occasion where poets read and perform their poetic pieces in front of a live audience.",
    time: "6:30 PM Onwards",
    date: "16th Feb",
    location: "Basketball Ground",
    rulebook: "Literary_Committee_Afreen.pdf",
  },
  {
    title: "What If?",
    imageUrl: "whatif",
    description:
      "Have you ever wondered what the world map would look like if Gandhi had taken to violence, or how would Brutus explain himself had Caesar recovered, or what would India be post the ongoing IT recession? If yes,here’s your chance to account your imagination of twisted conclusions and present it to a larger group, ready with their custom plot twists. So be ready as Vivacity 2K22 in association with the Literary Committee gives you the platform to talk about those unspoken, unsaid and unvoiced alternate endings, situations and scenarios.",
    time: "2:00 PM onwards",
    date: "17th Feb",
    location: "LT",
    rulebook: "Literary_Committee_What_If.pdf",
  },
  {
    title: "Open Discussion",
    imageUrl: "opensidcussion",
    description:
      "Vivacity'22 presents one of its indispensable organs, the literary committee. The Literary Committee is the flagbearer of iconic discussions among the most colourful minds among the attendees. An open discussion is a public dialogue with the purpose to express your views and explore many options and solutions. The goal of the event is to learn not to debate, to share not to persuade. Keeping away from generalisations of social groups, participants are expected to regard opinions remote from their own.",
    time: "12:00 PM onwards",
    date: "18th Feb",
    location: "LT",
    rulebook: "Literary_Committee_Open_Discussion.pdf",
  },
  {
    title: "Scribble Stories Workshop",
    imageUrl: "creative_writing",
    description:
      "This event includes a dedicated workshop on creative writing and various details related to content writing. Scribbled Stories, a well-known storytelling platform for creators, will be invited to this workshop, and they will be managing the complete seminar.",
    time: "2:00 PM - 6:00 PM",
    date: "19th Feb",
    location: "LT",
    rulebook: "Literary_Committee_Creative_Writing.pdf",
  },
];
