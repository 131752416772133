import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { categories } from "../../data/data";
import "./Sidebar.css";
import HamContext from "../../store/ham-context";
import useMediaQuery from "../../hooks/useMediaQuery";
import { motion } from "framer-motion";

export default function SideBar() {
  let id;
  const hamctx = useContext(HamContext);
  const isMobile = useMediaQuery("(max-width:800px)");
  const NavList = categories.map((element, index) => {
    id = element.split(" ").join("").toLowerCase();
    return (
      <motion.div
        key={index}
        initial={{ opacity: 0, translateX: -50 }}
        animate={{ opacity: 1, translateX: 0 }}
        transition={{ duration: 0.8 + index / 10 }}
      >
        <li
          className={`test  text-white select-none cursor-pointer font-Kojiro flex flex-col items-left font-extralight py-2 overflow-y-scroll text-2xl hover:text-red-200 ${
            isMobile ? "text-4xl" : ""
          }`}
          key={Math.random()}
        >
          <Link
            onClick={() => {
              hamctx.close();
            }}
            to={`/events/${id}`}
          >
            {element}
          </Link>
        </li>
      </motion.div>
    );
  });

  return (
    <div
      className={`h-full bgimg p-10 w-1/4 overflow-y-auto flex flex-col justify-start items-center ${
        isMobile ? "justify-center w-96" : ""
      }`}
    >
      <div
        className={` text-center pt-10 flex items-center justify-evenly w-full  select-none  mt-6 font-Kojiro font-normal eventhead`}
      >
        <div className="text-2xl opacity-50 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-purple-400">
          <Link to="/">Home</Link>
        </div>
        <div className="text-6xl text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-purple-400">
          <Link to="/events">Events</Link>
        </div>
        <div className="text-2xl opacity-50 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-purple-400">
          <Link to="/teams">Teams</Link>
        </div>
      </div>
      <div className={`mt-4 flex flex-col items-center`}>
        <ol className=" flex flex-col ">{NavList}</ol>
      </div>
    </div>
  );
}
