import React from "react";
import "./Listingteam.css";
import { motion } from "framer-motion";

const Listing = ({ data, open }) => {
  const { imageUrl, name } = data;

  return (
    <motion.div className="listing " whileHover={{ scale: 1.1 }}>
      <div className="listing__content">
        <div className="listing__image-container">
          <img
            className="listing__image"
            alt="real estate mansion"
            src={"https://d34bifzzrx7azc.cloudfront.net/teams/" + imageUrl + ".webp"}
          />
        </div>
        <div className="listing__details">
          <div className="flex flex-col items-center">
            <div className="font-normal text-3xl font-Kojiro select-none text-white">
              {name}
            </div>
            {/* <div className="text-lg font-serif text-blue-gray-400">
              17th Feb | 13:00 | OAT
            </div> */}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Listing;
