export const vignetteData = [
  {
    title: "TATTOO TALES",
    imageUrl: "tattootales",
    description:
      "I etch a fragment of my heart with every tattoo.Some say a tattoo is silent poetry. Intricate strokes curve and bend like the words in a poem. Tattoo is very unique too and personal to each and every individual. So, welcome to ‘Tattoo Tales’. Participate in this exhilarating event rich in nervousness, excitement and after all, the joy of painting your favourite tattoo on your partner’s arm. And if you are lucky enough, this might just be your day with that particular someone!",
    time: "3PM -5PM",
    date: "18th Feb",
    location: "Library Lawn",
    rulebook: "Vignette_Tattoo_Tales.pdf",
  },
  {
    title: "SPLASH",
    imageUrl: "face_splash",
    description:
      "Remember the happy days, when we used to laugh at a splash of paint on someone’s face? Well, we do hear your sighs and so Vignette presents to you ‘Splash’, the face painting competition. Enjoy the thrill of living the childhood dream of messing up someone’s face (we don’t recommend this though). Doodle your way through the whole mess of colours and paintbrushes to create the most attractive (or funny) countenance and win glorious prizes!",
    time: "2:00pm-5:00pm",
    date: "19th Feb",
    location: "CP",
    rulebook: "Vignette_Splash.pdf",
  },
  {
    title: "CONTRASTO",
    imageUrl: "contrasto",
    description:
      "“In the beginning it was all black and white.” So lets start from the beginning and delve into the world of black and white. This event involves painting on black and white sheets merged with white and black paint respectively to make something fantastic.",
    time: "9am-12:30pm",
    date: "18th Feb",
    location: "Library Lawn",
    rulebook: "Vignette_Contrasto.pdf",
  },
  {
    title: "COLORAMA",
    imageUrl: "colorama",
    description:
      "Like “Colorama” the built - in Python module to display text in different colours,Vignette brings to you an event where you can also showcase all your built-in artistic skills and pour out your imaginative selves to dissolve the sheets in colours of your ideas.",
    time: "2pm-5pm",
    date: "17th Feb",
    location: "Library Lawn",
    rulebook: "Vignette_Colorama.pdf",
  },
  {
    title: "ECLECTIC",
    imageUrl: "DSC_0257", //repeated image
    description:
      "Eclectic style is artsy, unique and comes with heaps of personality! Interiors done up with a classy blend of trends, time periods, use of color texture, and curios make for a characteristic design look in space: and that is an Eclectic Style.The art of combining these eras, designs and thoughts with autonomy to create matchlessness may sound simple, but it’s not! Eclectic style is all about harmony and the coming together of incongruent styles, juxtaposing textures, and contrasting colors to create a cohesive, beautifully comprehended room that wouldn’t be out of place in a home décor magazine as this is one design style that takes dynamism and a great eye. And since eclectic interior design is all about experimentation and play, do have fun with the freedom it allows.",
    time: "9-11am",
    date: "19th Feb",
    location: "Library Lawn",
    rulebook: "Vignette_Eclectic.pdf",
  },
];
