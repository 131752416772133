export const mediaData = [
  {
    title: "Duologue",
    imageUrl: "duologue",
    description:
      "A conversation without the judgment of appearances can speak volumes of a person.Our lives are full of quirky, memorable, and cherished moments. Such momentsare a part of ourselves and who we are and hence, worth sharing. Perhaps, thereis no other way to spread love and warmth better.Keeping this in mind, this Vivacity, Media Cell proudly presents, Duologue!",
    time: "3:30 PM- 6:00 PM",
    date: "19th Feb",
    location: "Library Lawn",
    rulebook: "Media_Cell_Duologue.pdf",
  },
  {
    title: "Spotlight",
    imageUrl: "spotlight",
    description:
      "An Open Mic Night organized in order to bring out the best of our younggenerations. It's an initiative taken by our team to promote the culture of poetry,storytelling, music, and stand-ups in Jaipur. What we are working with is not thesole ideology of just an open mic, but an open stage for the performers hidingtheir pocket diaries.",
    time: "5:00 PM- 7:30 PM",
    date: "17th Feb",
    location: "Central Plaza (CP)",
    rulebook: "Media_Cell_Spotlight.pdf",
  },
  {
    title: "FAMILY FEUD?",
    imageUrl: "DSC_0294", //repeated image
    description:
      "Inspired by Steve Harvey's famous television gameshow, Family feud is an attempt to engage our audiences in an evening filledwith laughter, and trivia.It's a tribute to this legendary show that still manages to bring families together,and keeps them glued to their television screens even after 24 seasons.",
    time: "10 AM - 11 AM",
    date: "19th Feb",
    location: "LT 2",
    rulebook: "Media_Cell_Family_Feud.pdf",
  },
];
