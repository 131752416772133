export const musicData = [
  {
    title: "Swaranjali",
    imageUrl: "swaranjali",
    description:
      "A portion or section of a Hindi song is performed in Swaranjali by a lone performer, whom an instrumentalist may accompany.",
    time: "9:00",
    date: "18th Feb",
    location: "Lecture Theatre",
    rulebook: "Capriccio_Swaranjali.pdf",
  },
  {
    title: "Solendious",
    imageUrl: "solendius",
    description:
      "A portion or section of an English song is performed in Swaranjali by a lone performer, whom an instrumentalist may accompany.",
    time: "9:00",
    date: "18th Feb",
    location: "Lecture Theatre",
    rulebook: "Capriccio_Solendious.pdf",
  },
  {
    title: "Duetto",
    imageUrl: "duetto",
    description:
      "A musical piece is sung by two contestants who may accompany an instrumentalist(s).",
    time: "13:00",
    date: "18th Feb",
    location: "Lecture Theatre",
    rulebook: "Capriccio_Duetto.pdf",
  },
  {
    title: "Gully War",
    imageUrl: "DSC_0332",
    description:
      "A participant is supposed to sing a rap of their own choice (preferably self-composed).",
    time: "14:30",
    date: "17th Feb",
    location: "CP",
    rulebook: "Capriccio_Gully_war.pdf",
  },
  {
    title: "Verismo",
    imageUrl: "verismo",
    description:
      "A single participant is supposed to perform a piece of music using an instrument of their choice.",
    time: "10:00",
    date: "19th Feb",
    location: "CP",
    rulebook: "Capriccio_Verismo.pdf",
  },
  {
    title: "Mescolanza",
    imageUrl: "mescolanza",
    description:
      "A Choir competition that shall enthrall the audience with their voice and music composition.",
    time: "16:30",
    date: "17th Feb",
    location: "SAC",
    rulebook: "Capriccio_Mescolanza.pdf",
  },
  {
    title: "Battle Of Bands",
    imageUrl: "DSC_0257", //repeated image
    description:
      "A group of people is supposed to perform a piece of music as a band.",
    time: "TBD",
    date: "TBD",
    location: "OAT Main Stage",
    rulebook: "Capriccio_Battle_Of_Bands.pdf",
  },
];
