export const sankalpData = [
  {
    title: "Sahyog",
    imageUrl: "sahyog",
    description:
      "They, the ones who are underprivileged, proved that they are no less than other children. The only thing they require is our love and support. The feeling of belongingness is what they are looking for. So it's time for us to hold their hands and walk together. Sahyog is an excellent event of Vivacity that provides these children a platform to enjoy and showcase their talent.",
    time: "12:00 – 4:00 PM",
    date: "17th Feb",
    location: "LT-1",
    rulebook: "Sankalp_Sahyog.pdf",
  },
];
