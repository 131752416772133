import Card from "./Card/Card";
import { motion } from "framer-motion";
import "../App.css";
import useMediaQuery from "../hooks/useMediaQuery";

export default function SpecificEvent(props) {
  const isDesktop = useMediaQuery("(min-width:800px)");
  // ${props.fontColor}
  return (
    <div
      className={`flex flex-col justify-start h-full bgimg_right pt-10  ${
        isDesktop ? "w-[calc(75vw-5px)]" : ""
      } items-center music object-contain`}
    >
      <div className={`text-6xl font-Kojiro text-pronights font-normal `}>
        {props.name}
      </div>

      <div className="properties overflow-y-scroll">
        {props.data.map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + index / 2 }}
          >
            <Card artistFlag={props.artistFlag} data={item} key={index} />
          </motion.div>
        ))}
      </div>
    </div>
  );
}
