import React from "react";
import "./Button.css";
export default function Button(props) {
  return (
    <button
      onClick={props.onClick}
      className={`${
        props.filled ? "test1" : "test1_khali"
      } text-white px-9 drop-shadow-md font-semibold py-2 rounded-lg bg-purpleButton 
      `}
    >
      {props.children}
    </button>
  );
}
