import "./App.css";
import { useContext } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RightSection from "./Components/Sections/RightSection";
import LeftSection from "./Components/Sections/LeftSection";
import HamContext from "./store/ham-context";
import { danceData } from "./data/dance";
import { musicData } from "./data/music";
import { quizData } from "./data/quizzing";
import { dramaData } from "./data/Rendition";
import { fashionData } from "./data/fashion";
import { vignetteData } from "./data/vignette";
import { lcData } from "./data/LC";
import { mediaData } from "./data/Media";
import { imagiData } from "./data/imagination";
import { artistData } from "./data/artists";
import { sankalpData } from "./data/Sankalp";
import useMediaQuery from "./hooks/useMediaQuery";
import { motion } from "framer-motion";
import LeftSectionteam from "./Components/TeamsPage/LeftSectionteam";
import RightSectionteam from "./Components/TeamsPage/RightSectionteam";
import * as dt from "./data/teams";
import * as on from "./data/onlyNames";
import { aavegData } from "./data/aaveg";
import IndexPage from "./Components/Index";

function App() {
  const isDesktop = useMediaQuery("(min-width:800px)");
  const hamctx = useContext(HamContext);
  const router = createBrowserRouter([
    {
      path: "",
      element: <IndexPage />,
    },
    {
      path: "/teams",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSectionteam
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSectionteam
                title="Fest Heads"
                Dataset={dt.eventHeads}
                className="flex flex-col items-center justify-center"
                fontColor="text-blue-400"
              />
            )}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/teams/festheads",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSectionteam
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSectionteam
                title="Fest Heads"
                Dataset={dt.eventHeads}
                className="flex flex-col items-center justify-center"
                fontColor="text-blue-400"
              />
            )}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/teams/artistsandfinanceteam",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSectionteam
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSectionteam
                title="Artists and Finance"
                Dataset={dt.afTeam}
                className="flex flex-col items-center justify-center"
                fontColor="text-yellow-400"
              />
            )}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/teams/developmentteam",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSectionteam
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSectionteam
                title="Development Team"
                Dataset={dt.devTeam}
                className="flex flex-col items-center justify-center"
                fontColor="text-purple-600"
              />
            )}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/teams/designteam",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSectionteam
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSectionteam
                title="Design Team"
                Dataset={dt.desTeam}
                onlyNames={on.creative}
                className="flex flex-col items-center justify-center"
                fontColor="text-cyan-400"
              />
            )}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/teams/mediateam",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSectionteam
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSectionteam
                title="Media Coverage"
                Dataset={dt.mediaTeam}
                className="flex flex-col items-center justify-center"
                fontColor="text-yellow-300"
              />
            )}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/teams/sponsorshipteam",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSectionteam
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSectionteam
                title="Sponsorship Team"
                Dataset={dt.sponsorsTeam}
                onlyNames={on.sponsorship}
                className="flex flex-col items-center justify-center"
                fontColor="text-orange-500"
              />
            )}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/teams/eventsteam",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSectionteam
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSectionteam
                title="Events Team"
                Dataset={dt.eventsTeam}
                onlyNames={on.events}
                className="flex flex-col items-center justify-center"
                fontColor="text-white"
              />
            )}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/teams/logistics",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSectionteam
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSectionteam
                title="Logistics Teams"
                onlyNames={on.logistics}
                className="flex flex-col items-center justify-center"
                fontColor="text-cyan-900"
              />
            )}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/teams/pr",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSectionteam
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSectionteam
                title="PR Team"
                Dataset={dt.prTeam}
                onlyNames={on.pr}
                className="flex flex-col items-center justify-center"
                fontColor="text-yellow-400"
              />
            )}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/events/dance",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSection
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSection
                title="Dance"
                Dataset={danceData}
                className="flex flex-col items-center justify-center"
                fontColor="text-blue-400"
              />
            )}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/events/sankalp",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSection
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSection
                title="Sankalp"
                Dataset={sankalpData}
                fontColor="text-yellow-400"
              />
            )}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/events/music",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSection
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSection
                title="Music"
                Dataset={musicData}
                className="music"
                fontColor="text-purple-600"
              />
            )}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/events/literature",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSection
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSection
                title="Literature"
                Dataset={lcData}
                fontColor="text-cyan-400"
              />
            )}{" "}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/events/quizzing",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSection
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSection
                title="Quizzing"
                Dataset={quizData}
                fontColor="text-yellow-300"
              />
            )}{" "}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/events/drama",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSection
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSection
                title="Drama"
                Dataset={dramaData}
                fontColor="text-orange-500"
              />
            )}{" "}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/events/fashion",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSection
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSection
                title="Fashion"
                Dataset={fashionData}
                fontColor="text-white"
              />
            )}{" "}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/events/vignette",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSection
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSection
                title="Vignette"
                Dataset={vignetteData}
                fontColor="text-cyan-900"
              />
            )}{" "}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/events/mediacell",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSection
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSection
                title="Media Cell"
                Dataset={mediaData}
                fontColor="text-blue-400"
              />
            )}{" "}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/events/imagination",
      element: (
        <div
          className={`  h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSection
            className={`${!isDesktop ? "items-center h-screen w-[25vw]" : ""}`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSection
                title="Imagination"
                Dataset={imagiData}
                fontColor="text-yellow-400"
              />
            )}{" "}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/events/artists",
      element: (
        <div
          className={` h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSection
            className={`bgimg ${
              !isDesktop ? "items-center h-screen w-[25vw]" : ""
            }`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSection
                title="Artists"
                Dataset={artistData}
                fontColor="text-purple-600"
                artistFlag="artist"
              />
            )}{" "}
          </motion.div>
        </div>
      ),
    },

    {
      path: "/events/aaveg",
      element: (
        <div
          className={` h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSection
            className={`bgimg ${
              !isDesktop ? "items-center h-screen w-[25vw]" : ""
            }`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSection
                title="Aaveg"
                Dataset={aavegData}
                fontColor="text-cyan-400"
              />
            )}{" "}
          </motion.div>
        </div>
      ),
    },
    {
      path: "/events/",
      element: (
        <div
          className={` h-screen  ${isDesktop ? "flex justify-between" : ""}`}
        >
          <LeftSection
            className={`bgimg ${
              !isDesktop ? "items-center h-screen w-[25vw]" : ""
            }`}
          />
          <motion.div
            key={Math.random()}
            initial={{ opacity: 0, translateX: -50 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{ duration: 0.8 + Math.random() / 2 }}
            className="overflow-y-scroll"
          >
            {!hamctx.isOpen && (
              <RightSection
                title="Artists"
                Dataset={artistData}
                fontColor="text-yellow-400"
                artistFlag="artist"
              />
            )}{" "}
          </motion.div>
        </div>
      ),
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
