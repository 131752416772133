export const danceData = [
  {
    title: "Razzmatazz",
    imageUrl: "DSC_0193",
    description:
      "Dance till you drop! Rock the fl oor with your ecstatic dance mix of intensity, energy andenthusiasm. Show all the people out there what you have got! Feel the music in yourheart & resonate to the beats, splash the jar of dance on the stage and ignite the fi re inthe Razzmatazz is back to rock the fl oor with most talented dance groups across thecountry. Dance your heart out, come and show us your grotesque madness. It's time tostep up a notch at VIVACITY-2022's RAZZMATAZZ. Bring out the best in you and show itthrough your dance. Conquer the dance fl oor and mesmerize the audience with sizzlingmoves this year at Vivacity 2022.",
    time: "10:00 am - 2:00 pm",
    date: "17th Feb",
    location: "SAC (Prelims) OAT(Finals)",
    rulebook: "Insignia_Razzmatazz.pdf",
  },
  {
    title: "Let's Tangle",
    imageUrl: "letstangle",
    description:
      "Let the heart beat faster and sink into the depth of music, romance, chemistry of lightsand joy. Create the bizarre of enthusiasm with your sizzling dance performance tonight,so keep the spirits high and lose yourself into the beats of music. Put your best footforward and prove your pair out to be the best in the crowd!",
    time: "12:00 pm - 2:00 pm",
    date: "18th Feb",
    location: "CP",
    rulebook: "Insignia_Let's Tangle.pdf",
  },
  {
    title: "Mudra",
    imageUrl: "DSC_0193",
    description:
      "Dancing is an art; the fl oor is your canvas; and you, the brush. Let your feet speak thehidden language of your soul; Let the rhythm carry you away..where the hand goes,follows the eye, where the eyes go, follows the heart...so here's your chance tomesmerize the audience with your grace. Get ready to enchant the audience with astupendously magnifi cent performance of yours.",
    time: "2:00 PM - 4:00 PM",
    date: "17th Feb",
    location: "SAC",
    rulebook: "Insignia_Mudra.pdf",
  },
  {
    title: "Pump it up",
    imageUrl: "pumpitup",
    description:
      "The world is at your feet with all the eyes set on you; but here you face it alone, foreverybody may not be able to get to this height. Forget about simultaneity andformation. Keeping the spirits high, cool your heels, it's time to step up a notch atVIVACITY-2022's solo dance competition. Spin and sway, prance and engross all in therhythm of cosmic tune and divine music. The heart beats have gone up and everyone iseager to sink in the captivating poetry of footsteps.",
    time: "10:00 am - 12:00 pm",
    date: "18th Feb",
    location: "CP",
    rulebook: "Insignia_Pump_It_Up.pdf",
  },
  {
    title: "The Outlaw",
    imageUrl: "DSC_0193",
    description:
      "The dance that unfolds in the most disorderly fashion and has literally no rules of play,the one that calls for those outlaws who are untamed by ant type. Come and establishyour rule among the various gangs of the street, face them one to one and knock themoff the scene!",
    time: "2:30 am - 4:30 pm",
    date: "18th Feb",
    location: "CP",
    rulebook: "Insignia_The Outlaw.pdf",
  },
];
