export const aavegData = [
  {
    title: "Hunkaar-Street Play",
    imageUrl: "DSC_0294",
    description:
      "People who don't express themselves die one piece at a time.And what better way to express than to Roar, Roar so high thatthe world is forced to sit and take notice? Hunkaar - NukkadNatak is an excellent platform to express one's views and is themost energetic event at VIVACITY.",
    time: "10:00 Am",
    date: "17th Feb",
    location: "Central Plaza (CP)",
    rulebook: "AAVEG_Hunkaar.pdf",
  },
];
