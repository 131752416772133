export const dramaData = [
  {
    title: "Rangshaala",
    imageUrl: "rangshaala",
    description:
      "Theatre, the greatest of all art forms, is the most immediate way in which a human being can share with another, the senseof what it means to be alive. Vivacity’22 brings to you “Rangshaala” inviting a potpourri of culture comprising a multitude of expressions and a plethora of emotions to celebrate this art form we lovingly call theatre. So, gather your troop to write, perform and present brilliance on stage.",
    time: "10:00 AM to 01:00 PM",
    date: "18th Feb",
    location: "SAC",
    rulebook: "Rendition_Rangshaala.pdf",
  },
  {
    title: "Paddhati",
    imageUrl: "paddhati",
    description:
      "I play the angel, I play the devil,See them merge, see them diverge,Various characters, various dimensions, I pull them together, ‘cause it’s all in me, And in all I am.Vivacity’22 presents “Paddhati” inviting you to delineate the real dramebaaz within you.",
    time: "09:00 AM to 11:30AM",
    date: "19th Feb",
    location: "SAC",
    rulebook: "Rendition_Paddhati.pdf",
  },
];
