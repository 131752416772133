export const teamCategories = [
  "Fest Heads",
  "Artists and Finance Team",
  "Development Team",
  "Design Team",
  "Media Team",
  "Sponsorship Team",
  "Events Team",
  "Logistics",
  "PR",
];

export const eventHeads = [
  {
    name: "Piyush Jhawar",
    imageUrl: "piyush",
    phoneNo: "",
  },
  {
    name: "Sagar Sheoran",
    imageUrl: "sagar",
    phoneNo: "",
  },
];
export const afTeam = [
  {
    name: "Pratyush Chahar",
    imageUrl: "pratyush",
    phoneNo: "",
  },
  {
    name: "Saumitra Vyas",
    imageUrl: "saumitra",
    phoneNo: "",
  },
  {
    name: "Harsh Rathore",
    imageUrl: "harsh",
    phoneNo: "",
  },
  {
    name: "Sarthak Goyal",
    imageUrl: "sarthak",
    phoneNo: "",
  },
  {
    name: "Diya Rajwanshi",
    imageUrl: "user1", // no image
    phoneNo: "",
  },
  {
    name: "Jatin Sharma",
    imageUrl: "Jatin_Sharma",
    phoneNo: "",
  },
];
export const devTeam = [
  {
    name: "Sumit Ailani",
    imageUrl: "sumit",
    phoneNo: "",
  },
  {
    name: "Anuj",
    imageUrl: "Anuj_Sharma",
    phoneNo: "",
  },
  {
    name: "Akash Mondal",
    imageUrl: "Akash_Mondal",
    phoneNo: "",
  },
  {
    name: "Sanket",
    imageUrl: "sanket", //no image
    phoneNo: "",
  },
  {
    name: "Bhavya Gupta",
    imageUrl: "bhavya",
    phoneNo: "",
  },
  {
    name: "Ayush Malviya",
    imageUrl: "Ayush_Malviya",
    phoneNo: "",
  },
  {
    name: "Yash Sain",
    imageUrl: "yash",
    phoneNo: "",
  },
  {
    name: "Devansh Bhatt",
    imageUrl: "devnash_bhatt",
    phoneNo: "",
  },
  {
    name: "Harsh Ojha",
    imageUrl: "harsh_ojha",
    phoneNo: "",
  },
  {
    name: "Sujal Jain",
    imageUrl: "sujal_jain",
    phoneNo: "",
  },
];
export const desTeam = [
  {
    name: "Jatin Gidwani",
    imageUrl: "jatin_1",
    phoneNo: "",
  },
  {
    name: "Divyansh Jain",
    imageUrl: "divyansh",
    phoneNo: "",
  },
];
export const mediaTeam = [
  {
    name: "Priyanshu Gautam",
    imageUrl: "Priyanshu-Gautam",
    phoneNo: "",
  },
  {
    name: "Lavish Mehta",
    imageUrl: "Lavish_Mehta",
    phoneNo: "",
  },
  {
    name: "Pranav Gupta",
    imageUrl: "user1", //no images
    phoneNo: "",
  },
];
export const sponsorsTeam = [
  {
    name: "Kartik",
    imageUrl: "Kartik_Rawat",
    phoneNo: "",
  },
  {
    name: "Ansh",
    imageUrl: "ansh",
    phoneNo: "",
  },
  {
    name: "Poojan",
    imageUrl: "user1", // no images
    phoneNo: "",
  },
  {
    name: "Pulkit",
    imageUrl: "Pulkit-Pandey",
    phoneNo: "",
  },
  {
    name: "Prakhar",
    imageUrl: "prakhar",
    phoneNo: "",
  },
  {
    name: "Tushar",
    imageUrl: "tushar",
    phoneNo: "",
  },
  {
    name: "Pratyush",
    imageUrl: "Pratyush-Srivastava",
    phoneNo: "",
  },
  {
    name: "Luv",
    imageUrl: "luv",
    phoneNo: "",
  },
];
export const eventsTeam = [
  {
    name: "Aniket",
    imageUrl: "aniket",
    phoneNo: "",
  },
  {
    name: "Maulik",
    imageUrl: "maulik",
    phoneNo: "",
  },
  {
    name: "Mayank",
    imageUrl: "mayank",
    phoneNo: "",
  },
  {
    name: "Swapnil",
    imageUrl: "swapnil",
    phoneNo: "",
  },
  {
    name: "Ayush",
    imageUrl: "ayush",
    phoneNo: "",
  },
  {
    name: "Utkal",
    imageUrl: "utkal",
    phoneNo: "",
  },
  {
    name: "Chaitanya",
    imageUrl: "Chaitanya_Sir",
    phoneNo: "",
  },
  {
    name: "Eklavya",
    imageUrl: "user1", // no images
    phoneNo: "",
  },
  {
    name: "Shubham",
    imageUrl: "user1", // no images
    phoneNo: "",
  },
];

export const prTeam = [
  {
    name: "Prabhav",
    imageUrl: "repeat", // no images
    phoneNo: "",
  },
];
