export const categories = [
  "Artists",
  "Dance",
  "Music",
  "Quizzing",
  "Drama",
  "Fashion",
  "Vignette",
  "Literature",
  "Media Cell",
  "Imagination",
  "Aaveg",
];
