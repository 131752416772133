export default function Index() {
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <iframe
        title="home-page"
        id="main-page-renderer"
        width={"100%"}
        height={"100%"}
        src="https://akmo3.github.io/Vivacity-2023/"
      />
    </div>
  );
}
