export const creative = ["Harshit Aseeja", "Shaambhavi Jain", "Harsh Kumar"];
export const sponsorship = [
  "Ajay Raj",
  "Ayush Agarwal",
  "Advit",
  "Vedant Wakchaware",
  "Vipul Gupta",
];

export const events = ["Pratham Khatri"];
export const logistics = [
  "Prachi Bansal",
  "Ananya Khadariya",
  "Shivansh Pathak",
  "Atul Raj Kalia",
  "Yagya Panwar",
  "Yuvraj Chawla",
  "Shivang Chauhan",
];

export const pr = [
  "Abhishek Dhawan",
  "Kartikeya Acharya",
  "Parishita Agarwal",
  "Mridul Khandelwal",
];
