import React from "react";
import "../TeamsPage/Listingteam.css";
import { motion } from "framer-motion";
import Button from "../UI/Button";

const Listing = ({ data, open, artistFlag }) => {
  const { imageUrl, title, rulebook } = data;
  const link = "https://d34bifzzrx7azc.cloudfront.net/rulebooks/" + rulebook;
  console.log(link);
  const downloadHandler = () => {
    fetch(link).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = rulebook;
        alink.click();
      });
    });
  };
  return (
    <motion.div className="listing w-max" whileHover={{ scale: 1.1 }}>
      <div className="listing__content">
        <div className="listing__image-container">
          <img
            className="listing__image"
            alt="real estate mansion"
            src={require("../../images/" + imageUrl + ".webp")}
            onClick={open}
          />
        </div>
        <div className="listing__details">
          <div className="flex flex-col items-center">
            <div
              className="font-normal text-3xl select-none font-Kojiro text-white"
              onClick={open}
            >
              {title}
            </div>
          </div>
          <div className="flex justify-evenly mb-6">
            <Button filled="true" onClick={open}>
              View Event
            </Button>
            {!artistFlag && <Button onClick={downloadHandler}>Rulebook</Button>}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Listing;
