export const imagiData = [
  {
    title: "Kalakriti",
    imageUrl: "kalakriti",
    description:
      "The best clicks by imagination members are put on for display. The main purpose of Kalakriti is showcase the talent and skill of students in the domain of photography.",
    time: "During the entire fest",
    date: "17th Feb",
    location: "Beside library entrance",
    rulebook: "IMAGINATION_Kalakriti.pdf",
  },
  {
    title: "Photography Contest",
    imageUrl: "photography",
    description:
      "This contest is open to all colleges.You can use any device to capture your shot; mobile cameras, DSLRs, or webcams are equally welcomed.The last date for entries is date. Any entries after this date and time will not be considered.Submission of previous work is allowed, but make sure that you submit your own photographs, at maximum you can submit 5 photos.Basic editing, including colour enhancement, the use of filters, and cropping of the photo is acceptable, provided any such editing does not affect the authenticity and genuineness of the photo, keep the original RAW photo with you till the contest ends.Advanced editing used to create illusions, deceptions, and manipulations, and the adding and removing of significant elements within the frame is prohibited. Photos that portray or otherwise include inappropriate and offensive content, including violence, human rights, and environmental violation, and any other content deemed to be contrary to the law, religious, cultural & moral traditions, and practices of India, are strictly prohibited and will be immediately discarded.Team Imagination reserves the right to disqualify any entry that is deemed inappropriate or does not conform to stated contest rules and make changes to it if required.",
    time: "During the entire fest",
    date: "17th Feb",
    location: "N/A",
    rulebook: "IMAGINATION_Photography Contest.pdf",
  },
  {
    title: "Workshops",
    imageUrl: "photography",
    description:
      "An informative session on one of the domains of the club (photography/designing/editing) by a famous and renowned artist.",
    time: "During the entire fest",
    date: "17th Feb",
    location: "LT-2",
    rulebook: "IMAGNATION_Workshops.pdf",
  },
  {
    title: "Creator’s Summit",
    imageUrl: "photography",
    description:
      "Some of the most well-known and prominent content creators from different communities, including vloggers, photographers, video makers, and more, will attend this summit and several workshops will be conducted. (Aman Dhattarwal is part of the line-up)",
    time: "During the entire fest",
    date: "17th Feb",
    location: "Basketball Court",
    rulebook: "",
  },
  {
    title: "Photo Booth",
    imageUrl: "photoboothimagi",
    description:
      "A dedicated place for people to get themselves captured for an amazing photograph. The photos will be printed and given to them on the spot.",
    time: "During the entire fest",
    date: "17th Feb",
    location: "TBD",
    rulebook: "IMAGINATION_Photobooth.pdf",
  },
  {
    title: "Reel Making Competition",
    imageUrl: "RMC",
    description:
      'The Reel making competition involves, for e.g., "A Day in the Life": Participants can create a reel showcasing a typical day in this cultural fest VIVACITY, highlighting the daily routines and activities across the fest.',
    time: "During the entire fest",
    date: "17th Feb",
    location: "N/A",
    rulebook: "IMAGINATION_Reel Making Competition.pdf",
  },
];
