import Events from "../specificEvent";

export default function RightSection(props) {
  return (
    <Events
      name={props.title}
      data={props.Dataset}
      fontColor={props.fontColor}
      artistFlag={props.artistFlag}
    />
  );
}
