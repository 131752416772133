export const quizData = [
  {
    title: "Football Auction",
    imageUrl: "football_auction",
    description:
      "Football Auction is a real life simulation of the auction which we witness before every Football season. The   aim will be maximising rating given a fi xed budget.",
    time: "12:00",
    date: "19th Feb",
    location: "LT9",
    rulebook: "Quizzinga_Football_Auction.pdf",
  },
  {
    title: "BAMBOOZLED",
    imageUrl: "bamboozled",
    description:
      "Bamboozled is Quizzinga's fl agshipentertainment quiz which takes place every year during Vivacity.",
    time: "10:00",
    date: "18th Feb",
    location: "LT9",
    rulebook: "Quizzinga_Banboozled.pdf",
  },
];
