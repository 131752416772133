import React, { useState } from "react";

const HamContext = React.createContext({
  isOpen: false,
  close: () => {},
  open: () => {},
  toggle: () => {},
});

export const HamContextProvider = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeHandler = () => {
    setIsOpen(false);
  };
  const openHandler = () => {
    setIsOpen(true);
  };
  const toggleHandler = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    <HamContext.Provider
      value={{
        isOpen: isOpen,
        close: closeHandler,
        open: openHandler,
        toggle: toggleHandler,
      }}
    >
      {props.children}
    </HamContext.Provider>
  );
};

export default HamContext;
