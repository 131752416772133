export const artistData = [
  {
    title: "Amit Trivedi",
    imageUrl: "amit",
    description: "Singer/Entertainer here to make you dance",
    time: "TBD",
    date: "19th Feb",
    location: "OAT",
    rulebook: "",
  },
  {
    title: "Kumar Varun",
    imageUrl: "varun",
    description: "Comedian here to make you laugh",
    time: "13:00",
    date: "18th Feb",
    location: "OAT",
    rulebook: "",
  },
];
