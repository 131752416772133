export const fashionData = [
  {
    title: "Vogue",
    imageUrl: "vogue",
    description:
      "India is the one-stop destination for women's fashion, beauty, lifestyle and entertainment content.Vogue is the original! Vogue is a term that connotes the culture of fashion, style, and enjoyment that shapes opinions and inspires creativity. Vivacity brings before you the fashion event “Vogue”, one of the main highlights of the gala attracting a handsome crowd. Fashion shows are a great way to show the people the new and unique kinds of dressing sense of people. The costumes are spectacular throughout, from the delicately embroidered capes to the glittering jewellery and feather light necklace. There’s a style perfect for everyone.",
    time: "10:00 AM to 2:00 PM",
    date: "18th Feb",
    location: "OAT",
    rulebook: "Eminence_The_Vouge_Show.pdf",
  },
];
