import React from "react";
import Listingteam from "./Listingteam";
// import Overlay from "./Overlay";
// import Modal from "./Modal";
// import { AnimatePresence } from "framer-motion";

const Card = ({ data, artistFlag }) => {
  // const [open, setOpen] = useState(false);

  // const openModal = () => {
  //   setOpen(true);
  // };

  // const closeModal = () => {
  //   setOpen(false);
  // };

  return (
    <>
      {/* open={openModal} */}
      <Listingteam data={data} />
      {/* <AnimatePresence>
        {open && (
          <Overlay close={closeModal}>
            <Modal data={data} close={closeModal} />
          </Overlay>
        )}
      </AnimatePresence> */}
    </>
  );
};

export default Card;
