import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { teamCategories } from "../../data/teams";
import "./Sidebarteam.css";
import HamContext from "../../store/ham-context";
import useMediaQuery from "../../hooks/useMediaQuery";
import { motion } from "framer-motion";

export default function SideBar() {
  let id;
  const hamctx = useContext(HamContext);
  const isMobile = useMediaQuery("(max-width:800px)");
  const NavList = teamCategories.map((element, index) => {
    id = element.split(" ").join("").toLowerCase();
    return (
      <motion.div
        key={index}
        initial={{ opacity: 0, translateX: -50 }}
        animate={{ opacity: 1, translateX: 0 }}
        transition={{ duration: 0.8 + index / 10 }}
        className="overflow-y-scroll"
      >
        <li
          className={`test text-white select-none cursor-pointer font-Kojiro flex flex-col items-left font-extralight py-3 overflow-y-scroll text-2xl hover:text-red-200 ${
            isMobile ? "text-4xl" : ""
          }`}
          key={Math.random()}
        >
          <Link
            onClick={() => {
              hamctx.close();
            }}
            to={`/teams/${id}`}
          >
            {element}
          </Link>
        </li>
      </motion.div>
    );
  });

  return (
    <div
      className={`bgimg pt-10 h-full w-1/4 overflow-y-auto flex flex-col justify-start items-center ${
        isMobile ? "justify-center w-96" : ""
      }`}
    >
      <div className=" text-center flex  items-center justify-evenly w-full  select-none  mt-6 font-Kojiro font-normal eventhead">
        <div className="text-2xl opacity-50 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-purple-400">
          <Link to="/">Home</Link>
        </div>
        <div className="text-6xl text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-purple-400">
          <Link to="/teams">Teams</Link>
        </div>
        <div className="text-2xl opacity-50 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-purple-400">
          <Link to="/events">Events</Link>
        </div>
      </div>
      <div className={`mt-4 flex flex-col items-center`}>
        <ul className=" flex flex-col">{NavList}</ul>
      </div>
    </div>
  );
}
