import React, { useContext } from "react";
import SideBar from "../SideBar/SideBar";
import { GiHamburgerMenu } from "react-icons/gi";
import "./LeftSection.css";
import useMediaQuery from "../../hooks/useMediaQuery";
import HamContext from "../../store/ham-context";

export default function LeftSection(props) {
  const isMobile = useMediaQuery("(max-width: 800px)");
  const isDesktop = useMediaQuery("(min-width: 800px)");
  const hamctx = useContext(HamContext);

  return (
    <>
      {isMobile && (
        <GiHamburgerMenu
          color="white"
          size={35}
          onClick={() => {
            hamctx.toggle();
          }}
          className="z-40 fixed HamIcon"
        />
      )}
      {hamctx.isOpen && isMobile && <SideBar className={`test`} />}
      {isDesktop && <SideBar />}
    </>
  );
}
